import T3ServiceClassDecorator from './t3-service-class-decorator'
import axios from 'axios'

enum Status {
    INPUT = 'input',
    REQUESTING = 'requesting',
    SUCCESS = 'success',
    ERROR = 'error'
}


export default class AxiosService extends T3ServiceClassDecorator {

    static serviceName = 'axios-service'

    private readonly globalConfig: any

    private status: string

    constructor(context) {
        super(context)

        this.globalConfig = context.getGlobalConfig()

        this.status = Status.INPUT
    }

    public submitData(endpoint: string, csrf_token: string, data: {}) {
        this.setModuleStatus(Status.REQUESTING)

        const jsonData = {
            ...data,
            [this.globalConfig.honeypotFieldName]: '',
        }

        const formData = new FormData()
        for (const key in jsonData) {
            formData.append(key, jsonData[key])
        }

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-CSRFToken': csrf_token,
            },
        }

        return axios.post(endpoint, formData, config)
            .then(response => {
                this.onSuccess(response)
                return response
            })
            .catch(error => {
                this.onError(error)
                throw error
            })
    }


    private onSuccess(response) {
        this.setModuleStatus(Status.SUCCESS)
    }

    private onError(error) {
        this.setModuleStatus(Status.ERROR)
    }

    private setModuleStatus(newStatus: string) {
        this.status = newStatus
    }

    get submitStatus(): string {
        return this.status
    }
}

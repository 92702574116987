/**
 * Intercom Class
 *
 * This class manages the integration of the Intercom chat service within the application.
 * It extends the T3ClassDecorator and handles the loading of the Intercom script,
 * the display of the chatbot button, and user interactions with the Intercom service.
 *
 * The loading and display of the Intercom module are conditionally controlled based on
 * user consent cookies and the presence of specific DOM elements that prevent loading.
 *
 */

import Broadcasts from '~/app/Broadcasts'
import T3ClassDecorator from '~/app-modules/modules/t3-class-decorator'
import { Constants } from '~/app/Constants'

export default class Intercom extends T3ClassDecorator {

    static moduleName = 'intercom'
    static messages = [
        Broadcasts.Intercom.OPEN,
        Broadcasts.Intercom.CLOSE,
        Broadcasts.Intercom.LOAD,
        Broadcasts.Intercom.PREVENT_LOAD,
        Broadcasts.Intercom.ENABLE_LOAD,
    ]
    static preventLoadSelectors = [
        '[data-ref="bike-advisor"]'
    ]

    constructor(context) {
        super(context)

        this.buttonTemplate = this._createElementFromHTML(
            this.module.querySelector('[data-ref="intercom-chatbot-button-template"]').innerHTML
        )
        this.intercomScriptConfig = this.module.querySelector('[data-ref="intercom-script-config"]')
        this.intercomScript = this.module.querySelector('[data-ref="intercom-script"]')
        this.config = this.context.getConfig()
        this.isChatbotAvailable = this.config.isChatbotAvailable
        this.shouldOpenOnLoad = this.config.shouldOpenOnLoad
        this.preventFromLoading = false
        this.isChatbotOpen = false

        this.cookieService = this.context.getService('cookie-service')
        this.scollLockHandler = this.context.getService('scroll-lock-handler')
    }

    init () {
        this._checkLoadingConditions([
                () => this.cookieService.getCookie('rm.cookies_advertising_accepted'),
                () => this.cookieService.getCookie('rm.cookies_functional_accepted'),
                () => this.cookieService.getCookie('rm.cookies_performance_accepted'),
                () => !this._shouldPreventLoad()
            ]
        )
    }

    destroy() {

    }

    /**
     * Handles incoming broadcast message for the Intercom module.
     *
     * @param {string} name - The name of the broadcast message.
     */
    onmessage(name) {
        if (!this.isChatbotAvailable) return

        switch (name) {
            case Broadcasts.Intercom.LOAD:
                if (this.preventFromLoading) return
                this.context.broadcast(Broadcasts.MODEL_SERIES.DISABLE_SCROLL_BUTTON)
                this._loadIntercomScript()
                break
            case Broadcasts.Intercom.OPEN:
                this._toggleChatbot()
                if (window.innerWidth <= Constants.SCREEN_INTERCOM_MOBILE) {
                    this.scollLockHandler.disableScroll()
                }
                break
            case Broadcasts.Intercom.CLOSE:
                this._toggleChatbot()
                if (window.innerWidth <= Constants.SCREEN_INTERCOM_MOBILE) {
                    this.scollLockHandler.enableScroll()
                }
                break
            case Broadcasts.Intercom.PREVENT_LOAD:
                this.preventFromLoading = true
                break
            case Broadcasts.Intercom.ENABLE_LOAD:
                this.preventFromLoading = false
                break
        }
    }

    /**
     * Retrieves the necessary DOM elements for the Intercom module.
     *
     * @private
     */
    _getElements() {
        this.button = this.module.querySelector('[data-ref="intercom-chatbot-button"]')
        this.openIcon = this.module.querySelector('[data-ref="open-icon"]')
        this.closeIcon = this.module.querySelector('[data-ref="close-icon"]')
    }

    /**
     * Loads the Intercom script and its configuration.
     *
     * If the Intercom script is successfully loaded, it appends the button template
     * and registers to the Intercom hooks. The `getIntercomScriptPromise` function
     * is defined in `intercom-container.html` and is used to handle the promise returned
     * when loading the Intercom script.
     *
     * @private
     */
    _loadIntercomScript() {
        const configScript = this.intercomScriptConfig.cloneNode(true)
        const mainScript = this.intercomScript.cloneNode(true)

        mainScript.async = true

        document.body.appendChild(configScript)
        document.body.appendChild(mainScript)

        if (typeof window.getIntercomScriptPromise === 'function') {
            window.getIntercomScriptPromise()
                .then(() => {
                    this._appendButtonTemplate()
                    this._registerIntercomHooks()

                    if (this.shouldOpenOnLoad) {
                        this._intercomWhenReady(() => {
                            window.Intercom('show')
                        })
                    }
                })
                .catch(error => {
                    console.error('Failed to load Intercom Script')
                    console.error(error)
                })
        }
    }

    /**
     * Appends the button template to the module's DOM.
     *
     * @private
     */
    _appendButtonTemplate() {
        if (this.module && this.buttonTemplate) {
            this.module.appendChild(this.buttonTemplate)
            this._getElements()
            setTimeout(() => {
                this.button.classList.add('is-visible')
            }, 1000)
        }
    }

    /**
     * Adds event listeners for the Intercom chat events.
     *
     * Listens for the 'show' and 'hide' events from Intercom and broadcasts the
     * corresponding messages. Additionally, it registers a click handler that closes
     * the chat window if the user clicks outside of the Intercom launcher.
     * It also sends custom events to Google Analytics 4 (GA4) to track the opening and closing
     * of the Intercom chat, including the trigger type (auto or click) and whether the interaction
     * was automatic or user-initiated.
     *
     * @private
     */
    _registerIntercomHooks() {
        //Define trigger type based on initial configuration
        let triggerType = this.shouldOpenOnLoad ? 'auto' : 'click'

        this._intercomWhenReady(() => {
            window.Intercom('onShow', () => {
                const nonInteraction = triggerType === 'auto'

                this.context.broadcast(Broadcasts.Intercom.OPEN)

                window.ga4Custom('intercom_chat_open', {
                    event_trigger: triggerType,
                })

                // Switch triggerType to 'click' after the initial auto open
                triggerType = 'click';

                document.addEventListener('click', this._handleClickOutside.bind(this))
            })

            window.Intercom('onHide', () => {
                this.context.broadcast(Broadcasts.Intercom.CLOSE)

                window.ga4Custom('intercom_chat_close', {
                    event_trigger: triggerType,
                })
                document.removeEventListener('click', this._handleClickOutside.bind(this))
            })
        })
    }

    /**
     * Toggles the Intercom chatbot's open or closed state.
     *
     * Updates the button state and icon visibility to reflect the current state.
     *
     * @private
     */
    _toggleChatbot() {
        const isOpening = !this.isChatbotOpen

        this.button?.classList.toggle('is-toggled', isOpening)

        this.button?.setAttribute('aria-expanded', isOpening)
        this.openIcon?.setAttribute('aria-hidden', isOpening)
        this.closeIcon?.setAttribute('aria-hidden', !isOpening)

        this.isChatbotOpen = isOpening
    }


    /**
     * Creates a new HTML element from a string.
     *
     * @param {string} htmlString - The HTML string to convert into a DOM element.
     * @returns {HTMLElement} - The created HTML element.
     * @private
     */
    _createElementFromHTML(htmlString) {
        var div = document.createElement('div')
        div.innerHTML = htmlString.trim()
        const firstChild = div.firstChild

        return (firstChild instanceof HTMLElement) ? firstChild : document.createElement('div')
    }

    /**
     * Function to ensure Intercom is ready before executing a callback.
     * It checks if Intercom is ready, and retries until successful.
     *
     * @param {Function} callback - The function to execute once Intercom is ready.
     */
    _intercomWhenReady(callback) {
        if (typeof window.Intercom === 'function') {
            setTimeout(() => callback(), 1000)
        }
        else {
            setTimeout(() => this._intercomWhenReady(callback), 1000)
        }
    }

    /**
     * Handles clicks outside of the Intercom launcher to close the chat window.
     *
     * If the Intercom chat window is open and the user clicks outside of the
     * launcher button, it calls window.Intercom('hide') to close the chat.
     *
     * @private
     * @param {Event} event - The click event object.
     */
    _handleClickOutside(event) {
        if (this.isChatbotOpen && !this.button.contains(event.target)) {
            window.Intercom('hide')
        }
    }

    /**
     * Checks multiple loading conditions based on the provided array of condition functions.
     * Broadcasts the loading of the Intercom chatbot if the conditions are met based on the configuration.
     *
     * @method checkLoadingConditions
     * @param {Array<function>} conditions - An array of functions that return a boolean indicating if the condition is met.
     * @param {boolean} requireAll - A flag indicating if all conditions must be met.
     */
    _checkLoadingConditions(conditions, requireAll = true) {
        const results = conditions.map(condition => condition())
        const shouldLoadChatbot = requireAll ? results.every(result => result) : results.some(result => result)
        if (shouldLoadChatbot) {
            this.context.broadcast(Broadcasts.Intercom.LOAD)
        }
        else {
            this.preventFromLoading = true
        }
    }

    /**
     * Determines if loading should be prevented based on the presence of specific DOM elements.
     *
     * @private
     * @returns {boolean} - Returns true if any are found, otherwise false.
     */
    _shouldPreventLoad() {
        return Intercom.preventLoadSelectors.some(selector => document.querySelector(selector) !== null)
    }
}

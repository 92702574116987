var Broadcasts = {

    // css, dom, js fertig geladen, Box.Application ausgeführt
    APP_READY: 'Broadcast.appReady',
    SECTIONS_REGISTERED: 'Broadcast.sectionsRegistered',
    PRELOAD_DONE: 'Broadcast.preloadDone',
    SECTIONS_READY: 'Broadcast.sectionsReady',

    WINDOW_RESIZE: 'Broadcast.windowResize',
    WINDOW_RESIZE_WIDTH: 'Broadcast.windowResizeWidth',
    WINDOW_RESIZE_HEIGHT: 'Broadcast.windowResizeHeight',
    WINDOW_ORIENTATION_CHANGE: 'Broadcast.windowOrientationChange',
    WINDOW_RESIZE_FIXED_CONTENT: 'Broadcast.windowResizeFixedContent',

    SCROLL: 'Broadcast.scroll',
    SCROLL_FAST: 'Broadcast.scrollFast',
    SCROLL_END: 'Broadcast.scrollEnd',

    SECTION_IN_VIEWPORT: 'Broadcast.sectionInViewport',

    ACCORDION_COLLAPSED: 'Broadcast.accordionCollapsed',
    ACCORDION_EXPANDED: 'Broadcast.accordionExpanded',

    FILTER_SELECTED: 'Broadcast.filterSelected',

    RETAILER_SEARCH_SUBMIT: 'Broadcast.retailerSearchSubmit',
    RETAILER_MAP_OPEN: 'Broadcasts.retailerMapOpen',
    RETAILER_MAP_UPDATE: 'Broadcasts.retailerMapUpdate',

    ACCORDION_ITEMS_CHANGED: 'Broadcasts.accordionItemsChanged',
    ACCORDION_OPENED: 'Broadcasts.accordionOpened',
    ACCORDION_CLOSED: 'Broadcasts.accordionClosed',

    HREF_CLICKED: 'Broadcasts.hrefClicked',

    UPDATE_SECTIONS_DONE: 'Broadcasts.updateSectionsDone',

    // m14 + m16, Produkt / Bike
    BIKE_COLOR_CHANGED: 'Broadcasts.bikeColorChanged',

    M27_LINK_CLICKED: 'Broadcasts.m27LinkClicked',

    //händlersuche, Suchergebnisse sind geladen und angezeigt
    RETAILER_LOADED: 'Broadcasts.retailerListed',

    OPEN_NAV_CLICKED: 'Broadcasts.openNavClicked',

    // Checkout and Payment
    TABBEDFORM_VALIDATE: 'Broadcasts.tabbedFormValidate',
    TABBEDFORM_REGISTER: 'Broadcasts.tabbedFormRegister',
    SHIPPING_METHOD_CHANGE: 'Broadcasts.shippingMethodChange',
    PAYMENT_METHOD_CHANGE: 'Broadcasts.paymentMethodChange',
    PAYMENT_UPDATE_STATE: 'Broadcasts.paymentUpdateState',
    PAYMENT_SUBMIT_ERROR: 'Broadcasts.paymentSubmitError',
    PAYMENT_SUBMIT_SUCCESS: 'Broadcasts.paymentSubmitSuccess',

    USER_ACCEPTED_LANGUAGE : 'Broadcasts.userAcceptedLanguage',

    //m45-user-gallery
    OPEN_LIGHTBOXGALLERY_REQUESTED: 'Broadcasts.openLightboxGalleryRequested',
    CLOSE_LIGHTBOXGALLERY_REQUESTED: 'Broadcasts.closeLightboxGalleryRequested',
    ADDED_IMAGES_TO_LIGHTBOX: 'Broadcasts.addedImagesToLightbox',

    Overlay: {
        OVERLAY_READY: 'Broadcasts.Overlay.overlayReady',
        BIKE_CHOSEN: 'Broadcasts.Overlay.bikeChosen'
    },

    CustomSelect: {
        FORCE_POPULATE: 'Broadcasts.CustomSelect.forcePopulate',
        OPENED: 'Broadcasts.CustomSelect.opened'
    },

    Slider: {
        INITIALIZED: 'Broadcass.Slider.Initialized'
    },

    Tabs: {
        BEFORE_OPEN: 'Broadcast.Tabs.BeforeOpen',
        OPEN: 'Broadcast.Tabs.Open',
        OPENED: 'Broadcast.Tabs.Opened'
    },

    Emo: {
        FORCE_UPDATE: 'Broadcast.Emo.forceUpdate'
    },

    Navigation: {
        SHOW_BACKDROP_REQUESTED: 'Broadcasts.Navigation.showBackdropRequested',
        HIDE_BACKDROP_REQUESTED: 'Broadcasts.Navigation.hideBackdropRequested',
        HIDE_BACKDROP_INSTANT_REQUESTED: 'Broadcasts.Navigation.hideBackdropInstantRequested',
        ON_BACKDROP_CLICKED: 'Broadcasts.Navigation.onBackdropClicked',

        OVERLAY_OPENED: 'Broadcasts.Navigation.overlayOpened',

        OPEN_NAV_REQUESTED: 'Broadcasts.Navigation.openNavRequested',
        CLOSE_NAV_REQUESTED: 'Broadcasts.Navigation.closeNavRequested',

        OPEN_OVERLAY_REQUESTED: 'Broadcasts.Navigation.openOverlayRequested',
        CLOSE_OVERLAY_REQUESTED: 'Broadcasts.Navigation.closeOverlayRequested',

        OPEN_SEARCH_REQUESTED: 'Broadcasts.Navigation.openSearchRequested',
        CLOSE_SEARCH_REQUESTED: 'Broadcasts.Navigation.closeSearchRequested',

        OPEN_LANGCHOOSER_REQUESTED: 'Broadcasts.Navigation.openLangchooserRequested',
        CLOSE_LANGCHOOSER_REQUESTED: 'Broadcasts.Navigation.closeLangchooserRequested'
    },

    Configurator: {
        INIT_FEATURES: 'Broadcasts.Configurator.initFeatures',
        UPDATE_FEATURE: 'Broadcasts.Configurator.updateFeature',
        UPDATE_STEP_BY_LINK: 'Broadcasts.Configurator.updateStepByLink',
        UPDATE_BIKE_CODE: 'Broadcasts.Configurator.updateBikeCode',
        UPDATE_BIKE_PRICE: 'Broadcasts.Configurator.updatePrice',
        UPDATE_IMAGE: 'Broadcasts.Configurator.updateImage',
        UPDATE_SCROLL_POS: 'Broadcasts.Configurator.updateScrollPos',

        UPDATED_BLACKLISTED_CHARACTERISTICS: 'Broadcasts.Configurator.updateBlacklistedCharacteristics',
        BLACKLIST_ERROR: 'Broadcasts.Configurator.blacklistError',

        FEATURE_CURRENT_STEP: 'Broadcasts.Configurator.featureCurrentStep',

        TOOLTIP_APPLY_CLICK: 'Broadcasts.Configurator.tooltipApplyClick',
        TOOLTIP_CANCEL_CLICK: 'Broadcasts.Configurator.tooltipCancelClick'
    },

    ProductDetail: {
        INITED_COMBINABLES: 'Broadcast.ProductDetail.initedCombinables',
        INITED_VARIANTS: 'Broadcast.ProductDetail.initedVariants',
        UPDATED_VARIANTS: 'Broadcast.ProductDetail.updatedVariants',
        UPDATE_VARIANT: 'Broadcast.ProductDetail.updateVariant',
        UPDATE_PRICE: 'Broadcasts.ProductDetail.updatePrice',
        UPDATE_DEFAULT_IMAGE: 'Broadcasts.ProductDetail.updateDefaultImage',
        UPDATE_CHECKOUT_BUTTON: 'Broadcasts.ProductDetail.updateCheckoutButton',
    },

    Booking: {
        UPDATE: 'Broadcasts.Booking.Update',
        UPDATE_CARD: 'Broadcasts.Booking.updateCard',
        UPDATE_PREVIEW_RETAILER: 'Broadcasts.Booking.updatePreviewRetailer',
        UPDATE_DISCOUNT: 'Broadcasts.Booking.updateDiscount',
        INITIALIZED_BOOKING_MODEL: 'Broadcasts.Booking.initializedBookingModel',
    },

    BookingTabs: {
        TAB_CHANGE: 'Broadcasts.Booking.tabChange'
    },

    MAPS_VIEW_UNLOCKED: 'Broadcasts.mapsViewUnlocked',

    COOKIES_ALL_ACCEPTED: 'Broadcasts.cookiesAccepted',
    COOKIES_FUNCTIONAL_ACCEPTED: 'Broadcasts.functionalCookies',
    COOKIES_PERFORMANCE_ACCEPTED: 'Broadcasts.performanceCookies',
    COOKIES_ADVERTISING_ACCEPTED: 'Broadcasts.advertisingCookies',
    COOKIE_SETTINGS_SAVED: 'Broadcasts.cookieSettingsSaved',

    OPEN_MAPS_PRIVACY_LAYER_REQUESTED: 'Broadcasts.Navigation.openMapsEnableLayerRequested',
    CLOSE_MAPS_ENABLE_LAYER_REQUESTED: 'Broadcasts.Navigation.closeMapsEnableLayerRequested',

    OPEN_ACCORDION_EXPAND_LAYER_REQUESTED: 'Broadcasts.Navigation.openAccordionExpandLayerRequested',
    CLOSE_ACCORDION_EXPAND_LAYER_REQUESTED: 'Broadcasts.Navigation.closeAccordionExpandLayerRequested',

    FORM_SUCCESS: 'Broadcasts.formSuccess',
    TRIGGER_RELOAD: 'Broadcasts.triggerReload',

    M51_OVERLAY_MAIL_UPDATE_SUBSCRIBE_CLICKED: 'Broadcasts.M51_OVERLAY_MAIL_UPDATE_SUBSCRIBE_CLICKED',
    M51_OVERLAY_MAIL_UPDATE_SUBSCRIBE_SUCCESS: 'Broadcasts.M51_OVERLAY_MAIL_UPDATE_SUBSCRIBE_SUCCESS',

    OPEN_SIDEBAR_FILTERS_REQUESTED: 'Broadcasts.OPEN_SIDEBAR_FILTERS_REQUESTED',
    CLOSE_SIDEBAR_FILTERS_REQUESTED: 'Broadcasts.CLOSE_SIDEBAR_FILTERS_REQUESTED',

    Intercom: {
        LOAD: 'Broadcasts.Intercom.load',
        PREVENT_LOAD: 'Broadcasts.Intercom.preventLoad',
        ENABLE_LOAD: 'Broadcasts.Intercom.enableLoad',
        OPEN: 'Broadcasts.Intercom.open',
        CLOSE: 'Broadcasts.Intercom.close',
    },

    MODEL_SERIES: {
        ENABLE_SCROLL_BUTTON: 'Broadcasts.MODEL_SERIES.ENABLE_SCROLL_BUTTON',
        DISABLE_SCROLL_BUTTON: 'Broadcasts.MODEL_SERIES.DISABLE_SCROLL_BUTTON',
    },

    CONTACT_FORM: {
        READY: 'Broadcasts.CONTACT_FORM.READY'
    }
}

if (typeof module !== 'undefined' && module.exports) {
    module.exports = Broadcasts
}

import Broadcasts from '~/app/Broadcasts'
import T3ClassDecorator from '~/app-modules/modules/t3-class-decorator'

export default class Contact extends T3ClassDecorator {

    static moduleName = 'contact'
    static messages = [
        Broadcasts.CONTACT_FORM.READY,
    ]

    constructor(context) {
        super(context)
        const module = context.getElement()
        const config = context.getConfig() || {}

        this.isUserAuthenticated = config.is_user_authenticated || false
        this.bike_choices = config.bike_choices || []
    }

    init() {
        if (this.isUserAuthenticated) {
            this._getElements()
            this._setCustomData()
            this._addEventListener()
        }
    }

    destroy() {
        this._removeEventListeners()
    }

    /**
     * Handles incoming broadcast message for the Contact module.
     *
     * @param {string} name - The name of the broadcast message.
     */
    onmessage(name) {
        switch (name) {
            case Broadcasts.CONTACT_FORM.READY:
                if (this.isUserAuthenticated && this.bike_choices?.length === 1) {
                    this._setDefaultOptions(this.bike_choices[0])
                }
                break
        }
    }

    _getElements() {
        this.containerChassis = this.module.querySelector('[data-ref="contact-form-chassis-number-input"]')
        this.containerSerial = this.module.querySelector('[data-ref="contact-form-series-number-input"]')
        this.containerRetailer = this.module.querySelector('[data-ref="contact-form-retailer-input"]')

        this.selectChassis = this.containerChassis?.querySelector('select')
        this.selectSerial = this.containerSerial?.querySelector('select')
        this.selectRetailer = this.containerRetailer?.querySelector('select')
    }

    _setCustomData() {
        const frameNumberSelectOptions = this.bike_choices.map(bike => {
            return {
                slug: bike.frameNumber,
                name: bike.frameNumber + (bike.personalizedName ? ' (' + bike.personalizedName + ')' : ''),
            }
        })
        const serialNumberSelectOptions = this.bike_choices.map(bike => {
            return {
                slug: bike.serialNumber,
                name: bike.serialNumber,
            }
        })
        this.containerChassis?.setAttribute('data-custom-json', JSON.stringify(frameNumberSelectOptions))
        this.containerSerial?.setAttribute('data-custom-json', JSON.stringify(serialNumberSelectOptions))
    }

    _setDefaultOptions(defaultBike) {
        if (!defaultBike) {
            console.error('Default bike data is missing.')
            return
        }

        this._selectOption(this.selectChassis, defaultBike.frameNumber)
        this._selectOption(this.selectSerial, defaultBike.serialNumber)
        this._selectOption(this.selectRetailer, defaultBike.retailer, true)
    }

    _addEventListener() {
        this.selectChassis?.addEventListener('change', event => this._handleChassisChange(event))
        this.selectSerial?.addEventListener('change', event => this._handleSeriesChange(event))
    }

    _removeEventListeners() {
        this.selectChassis?.removeEventListener('change', this._handleChassisChange)
        this.selectSerial?.removeEventListener('change', this._handleSeriesChange)
    }

    _handleChassisChange(event) {
        const selectedFrameNumber = event.target.value
        this._updateFieldsBasedOnChassis(selectedFrameNumber)
    }

    _handleSeriesChange(event) {
        const selectedSerialNumber = event.target.value
        this._updateFieldsBasedOnSeries(selectedSerialNumber)
    }

    _selectOption(inputElement, value, skipOptionCheck = false) {
        if (!inputElement)
            return

        if (skipOptionCheck) {
            let option = document.createElement('option')
            option.value = value
            option.textContent = value

            inputElement.appendChild(option)
            inputElement.value = value
            inputElement.classList.add('selected')
        } else {
            const options = inputElement.querySelectorAll('option')
            options.forEach(option => {
                if (option.value === value || option.textContent === value) {
                    option.selected = true
                    inputElement.classList.add('selected')
                } else {
                    option.selected = false
                }
            })
        }
    }

    _updateFieldsBasedOnChassis(frameNumber) {
        const selectedBike = this.bike_choices.find(bike => bike.frameNumber === frameNumber)
        if (selectedBike) {
            this._selectOption(this.selectSerial, selectedBike.serialNumber)
            this._selectOption(this.selectRetailer, selectedBike.retailer, true)
        }
    }

    _updateFieldsBasedOnSeries(serialNumber) {
        const selectedBike = this.bike_choices.find(bike => bike.serialNumber === serialNumber)
        if (selectedBike) {
            this._selectOption(this.selectChassis, selectedBike.frameNumber)
            this._selectOption(this.selectRetailer, selectedBike.retailer, true)
        }
    }
}
